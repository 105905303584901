import logoImg from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../store/zustand';
import { useThemeMode } from '../hooks/useThemeMode';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { IonIcon } from '@ionic/react';
import { closeOutline, menuOutline } from 'ionicons/icons';
import Avatar from './Avatar';

const Header = () => {
  const { t } = useTranslation();
  const { _toogleDarkMode, isDarkMode } = useThemeMode();

  const user = useAuthStore((state) => state.user);

  return (
    <header className="z-[100] h-[--m-top] fixed top-0 left-0 w-full flex items-center backdrop-blur-xl border-b border-slate-100 dark:border-slate-700">
      <div className="flex items-center w-full xl:px-6 px-2 max-lg:gap-10">
        <div className="2xl:w-[--w-side] lg:w-[--w-side-sm]">
          <div className="flex items-center gap-1">
            <button
              data-uk-toggle="target: #site__sidebar ; cls :!-translate-x-0"
              className="flex items-center justify-center w-8 h-8 text-xl rounded-full hover:bg-gray-100 xl:hidden dark:hover:bg-slate-600 group"
            >
              <IonIcon
                icon={menuOutline}
                className="text-2xl group-aria-expanded:hidden"
              />
              <IonIcon
                icon={closeOutline}
                className="hidden text-2xl group-aria-expanded:block"
              />
            </button>

            <div id="logo">
              <a href="/">
                <img
                  src={logoImg}
                  alt="Playsum Logo"
                  className="md:block hidden h-6 sm:h-8 w-auto"
                />
                <img
                  src={logoImg}
                  className="hidden max-md:block h-6 sm:h-8 w-auto"
                  alt="Playsum Logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 relative">
          <div className="max-w-[1220px] mx-auto flex items-center">
            <div className="flex items-center sm:gap-4 gap-2 absolute right-5 top-1/2 -translate-y-1/2 text-black">
              <div className="rounded-full relative cursor-pointer shrink-0 sm:w-9 sm:h-9 w-7 h-7 ">
                <Avatar userId={user?.id as string} username={user?.username} />
              </div>
              <div
                className="hidden bg-white rounded-lg drop-shadow-xl dark:bg-slate-800 w-64 border2 absolute z-50 [.uk-open]:block"
                data-uk-drop="offset:6;pos: bottom-right;animate-out: true; animation: uk-animation-scale-up uk-transform-origin-top-right "
              >
                <div className="p-4 py-5 flex items-center gap-4">
                  <div className="w-10 h-10 ">
                    <Avatar
                      userId={user?.id as string}
                      username={user?.username}
                    />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-sm font-medium text-black">
                      {user?.username}
                    </h4>
                    <div className="text-sm mt-1 text-neutral-500 dark:text-neutral-400">
                      {user?.email}
                    </div>
                  </div>
                </div>

                <hr className="dark:border-gray-600/60" />

                <nav className="p-2 text-sm text-black font-normal dark:text-white">
                  <Link to={'/account'}>
                    <div className="flex items-center gap-2.5 hover:bg-secondery p-2 px-2.5 rounded-md dark:hover:bg-white/10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        data-stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          data-stroke-linecap="round"
                          data-stroke-linejoin="round"
                          d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                        />
                        <path
                          data-stroke-linecap="round"
                          data-stroke-linejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      {t('Header.settings')}
                    </div>
                  </Link>
                  <button type="button" className="w-full">
                    <div className="flex items-center gap-2.5 hover:bg-secondery p-2 px-2.5 rounded-md dark:hover:bg-white/10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        data-stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          data-stroke-linecap="round"
                          data-stroke-linejoin="round"
                          d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                        />
                      </svg>
                      {t('Header.darkTheme')}
                      <span className="ml-auto p-0.5">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            defaultChecked={isDarkMode}
                            onClick={_toogleDarkMode}
                          />
                          <div className="relative w-9 h-5 bg-slate-200/40 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#db00ff]"></div>
                        </label>
                      </span>
                    </div>
                  </button>
                  <hr className="-mx-2 my-2 dark:border-gray-600/60" />
                  <Link to={'/logout'}>
                    <div className="flex items-center gap-2.5 hover:bg-secondery p-2 px-2.5 rounded-md dark:hover:bg-white/10">
                      <svg
                        className="w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          data-stroke-linecap="round"
                          data-stroke-linejoin="round"
                          data-stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        ></path>
                      </svg>
                      {t('Header.logout')}
                    </div>
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
